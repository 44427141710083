<template>
  <b-modal
    id="modal-selection-save"
    :title="modalTitle"
    size="md"
    button-size="sm"
    @show="onShow"
    @hidden="onHidden"
  >
    <div>
      <!-- Only show selection count if creating a new selection -->
      <p
        v-if="!isEditMode"
        class="mb-3"
      >
        {{ $t('modal.selection.reportCount', { count: selectedCount }) }}
      </p>

      <!-- Form for saving/updating the selection -->
      <form @submit.prevent="onFormSubmit">
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <b-form-input
                class="custom-input"
                :placeholder="$t('modal.selection.instruction')"
                v-model.trim="form.name"
              ></b-form-input>
              <!-- Validation error messages -->
              <label
                class="error"
                v-if="!$v.form.name.required && $v.form.name.$dirty"
              >
                {{ $t('validate.required') }}
              </label>
              <label
                class="error"
                v-if="!$v.form.name.minLength && $v.form.name.$dirty"
              >
                {{ $t('validate.minLength', { val: '2' }) }}
              </label>
              <label
                class="error"
                v-if="!$v.form.name.maxLength && $v.form.name.$dirty"
              >
                {{ $t('validate.maxLength', { val: '20' }) }}
              </label>
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </div>

    <!-- Modal footer with Save and Cancel buttons -->
    <template #modal-footer="{ hide }">
      <div class="d-flex justify-content-between align-items-center w-100">
        <small class="text-muted">* {{ $t('validate.required') }}</small>
        <div>
          <b-button
            type="button"
            size="sm"
            variant="primary"
            @click="onFormSubmit"
          >
            {{ $t('action.save') }}
          </b-button>
          <b-button
            size="sm"
            variant="light"
            @click="hide"
          >
            {{ $t('action.cancel') }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import axiosResource from '@/utils/axiosResource';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { globalSwal } from '@/plugins/globalSwal';

export default {
  name: 'ModalSelectionSave',
  props: {
    // If provided, the modal will be in edit mode.
    selectionToEdit: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        name: '',
      },
    };
  },
  validations: {
    form: {
      name: { required, minLength: minLength(2), maxLength: maxLength(20) },
    },
  },
  computed: {
    selectedCount() {
      return this.$store.getters.selectedCount;
    },
    isEditMode() {
      return !!this.selectionToEdit;
    },
    modalTitle() {
      return this.isEditMode
        ? this.$t('modal.selection.editTitle')
        : this.$t('modal.selection.title');
    },
  },
  methods: {
    onShow() {
      console.log(`isEditMode`, this.isEditMode);
      this.$v.$reset();
      // In edit mode, pre-populate with the current selection name.
      if (this.isEditMode) {
        this.form.name = this.selectionToEdit.name;
      } else {
        this.form.name = '';
      }
    },
    onHidden() {
      // Re-emit the hidden event so the parent can react to it.
      this.$emit('hidden');
    },

    onFormSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      this.$store.commit('loading', true);
      if (this.isEditMode) {
        // Update existing saved selection
        axiosResource
          .patch(`selection/saved/${this.selectionToEdit.selectionUuid}`, {
            name: this.form.name,
          })
          .then((response) => {
            this.$store.commit('loading', false);
            // Refresh the saved selections list.
            this.$store.dispatch('fetchSavedSelections');
            this.$bvModal.hide('modal-selection-save');
            globalSwal.successSimple(
              this.$t('explore.selection.success.selectionSaved')
            );
          })
          .catch((error) => {
            this.$store.commit('loading', false);
            globalSwal.errorSimple('Failed to update selection');
            console.error('Error updating selection:', error);
          });
      } else {
        // Create a new saved selection
        axiosResource
          .post('selection/save', { name: this.form.name })
          .then((response) => {
            this.$store.commit('loading', false);
            this.$store.dispatch('addSavedSelection', response.data.selection);
            this.$store.dispatch('fetchSavedSelections'); // Ensure all selections are up to date
            this.$bvModal.hide('modal-selection-save');
            globalSwal.successSimple(
              this.$t('explore.selection.success.selectionSaved')
            );
          })
          .catch((error) => {
            this.$store.commit('loading', false);
            // Check if error message indicates maximum selections have been reached.
            if (
              error.response &&
              error.response.data &&
              error.response.data.message === 'Maximum saved selections reached'
            ) {
              globalSwal.errorSimple(
                this.$t('explore.selection.error.maxSelectionsExceeded', {
                  max: 6,
                })
              );
            } else {
              globalSwal.errorSimple('Failed to save selection');
            }
            console.error('Error saving selection:', error);
          });
      }
    },
  },
};
</script>

<style scoped>
.custom-input {
  height: 36px;
}
.error {
  color: red;
  font-size: 12px;
}
</style>