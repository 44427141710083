<template>
  <div class="container-600">
    <div class="explore-navbar d-none d-md-flex">

      <div class="d-inline-flex explore-navbar-container-one">

        <!-- Filters button -->

        <button
          class="filter-button"
          @click="triggerSidebar()"
        >
          <font-awesome-icon
            icon="fa-solid fa-bars-filter"
            size="xl"
            class="filter-icon "
          />
        </button>

        <!-- Sort dropdown -->

        <el-dropdown
          @command="handleSortingCommand"
          placement="bottom-start"
        >
          <button class="filter-button">
            <font-awesome-icon
              icon="fa-regular fa-arrow-up-arrow-down"
              size="xl"
              class="filter-icon"
            />
          </button>
          <el-dropdown-menu slot="dropdown">
            <template v-for="item in sortingOptions">
              <el-dropdown-item
                :command="item.command"
                :class="{ 'selected-filter': item.command === currentSortingPreference }"
                :divided="item.divided"
                :key="item.command"
              >
                {{ $t(item.label) }}
              </el-dropdown-item>
            </template>
          </el-dropdown-menu>
        </el-dropdown>

        <!-- Spinner -->

        <div
          class="action-loader"
          v-loading="loading"
        ></div>

        <!-- Count -->

        <div class="count-container mr-2">
          <span>{{ $store.getters.qtt.toLocaleString() }} {{ $tc('explore.point', $store.getters.qtt).toUpperCase() }}</span>

          <!-- CHECKBOX Select All -->
          <b-form-checkbox
            id="checkbox-all"
            :checked="isAllSelected"
            name="checkbox-all"
            value="true"
            unchecked-value="false"
            class="ml-4"
            @change="toggleAllSelection"
            v-show="['tiny'].includes($store.getters.currentCard)"
            v-b-tooltip.hover.bottom.html
            :title="isAllSelected ? $t('explore.selection.unselectAll') : $t('explore.selection.selectAll')"
          />
        </div>
      </div>

      <!-- Status button group -->

      <el-button-group
        v-if="!isSmallScreen && $store.getters.selectedCount != 0"
        class="status-container mr-2"
      >
        <el-button
          class="explore-mode-btn status-OPEN"
          id=""
          @click="handleReportCommand('OPEN')"
          v-b-tooltip.hover.bottom.html
          :title="$t('status.OPEN')"
          size="small"
        >
          <font-awesome-icon
            icon="fa-regular fa-door-open"
            class="fa-lg"
          />
        </el-button>
        <el-button
          class="explore-mode-btn status-ADDRESSED"
          id=""
          @click="handleReportCommand('ADDRESSED')"
          v-b-tooltip.hover.bottom.html
          :title="$t('status.ADDRESSED')"
          size="small"
        >
          <font-awesome-icon
            icon="fa-regular fa-forward"
            class="fa-lg"
          />
        </el-button>
        <el-button
          class="explore-mode-btn status-ONGOING"
          id=""
          @click="handleReportCommand('ONGOING')"
          v-b-tooltip.hover.bottom.html
          :title="$t('status.ONGOING')"
          size="small"
        >
          <font-awesome-icon
            icon="fa-regular fa-hourglass-half"
            class="fa-lg"
          />
        </el-button>
        <el-button
          class="explore-mode-btn status-SOLVED"
          id=""
          @click="handleReportCommand('SOLVED')"
          v-b-tooltip.hover.bottom.html
          :title="$t('status.SOLVED')"
          size="small"
        >
          <font-awesome-icon
            icon="fa-regular fa-check-double"
            class="fa-lg"
          />
        </el-button>
        <el-button
          class="explore-mode-btn status-CLOSED"
          id=""
          @click="handleReportCommand('CLOSED')"
          v-b-tooltip.hover.bottom.html
          :title="$t('status.CLOSED')"
          size="small"
        >
          <font-awesome-icon
            icon="fa-regular fa-door-closed"
            class="fa-lg"
          />
        </el-button>
        <el-button
          class="explore-mode-btn status-ONHOLD"
          id=""
          @click="handleReportCommand('ONHOLD')"
          v-b-tooltip.hover.bottom.html
          :title="$t('status.ONHOLD')"
          size="small"
        >
          <font-awesome-icon
            icon="fa-regular fa-pause-circle"
            class="fa-lg"
          />
        </el-button>
        <el-button
          class="explore-mode-btn status-NOACTION"
          id=""
          @click="handleReportCommand('NOACTION')"
          v-b-tooltip.hover.bottom.html
          :title="$t('status.NOACTION')"
          size="small"
        >
          <font-awesome-icon
            icon="fa-regular fa-times-circle"
            class="fa-lg"
          />
        </el-button>
        <el-button
          class="explore-mode-btn status-DELETED"
          id=""
          @click="handleReportCommand('DELETED')"
          v-b-tooltip.hover.bottom.html
          :title="$t('status.DELETED')"
          size="small"
        >
          <font-awesome-icon
            icon="fa-regular fa-trash-alt"
            class="fa-lg"
          />
        </el-button>

        <!-- <el-dropdown
          size="small"
          @command="handleReportCommand"
        >
          <el-button size="small">
            <font-awesome-icon
              icon="fa-regular fa-angle-down"
              class="fa-lg"
            />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="customStatus">RÉ-OUVERT</el-dropdown-item>
            <el-dropdown-item command="customStatus">PERMANENT</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </el-button-group>

      <!-- WI, Comment, Attachments button group -->

      <el-button-group
        class="status-container mr-2"
        v-if="!isSmallScreen && $store.getters.selectedCount != 0"
      >
        <el-button
          class="explore-mode-btn"
          @click="handleReportCommand('WI')"
          v-b-tooltip.hover.bottom.html
          :title="$t('action.wi')"
          size="small"
        >
          <font-awesome-layers class="fa-lg">
            <font-awesome-icon
              icon="fa-light fa-square"
              transform="grow-6 rotate-45"
            />
            <font-awesome-layers-text
              class="gray8"
              transform="shrink-6"
              value="WI"
            />
          </font-awesome-layers>
        </el-button>
        <el-button
          class="explore-mode-btn"
          id=""
          @click="handleReportCommand('COMMENT')"
          v-b-tooltip.hover.bottom.html
          :title="$t('action.comment')"
          size="small"
        >
          <font-awesome-icon
            icon="fa-regular fa-comment"
            class="fa-lg"
          />
        </el-button>

        <el-dropdown
          size="small"
          @command="handleReportCommand"
        >
          <el-button size="small">
            <font-awesome-icon
              icon="fa-regular fa-paperclip"
              class="fa-lg"
            />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="closurePhoto"><font-awesome-icon
                icon="fa-regular fa-image"
                class="mr-2"
              />{{ $t('action.closurePhoto') }}</el-dropdown-item>
            <el-dropdown-item command="solvedPhoto"><font-awesome-icon
                icon="fa-light fa-image"
                class="mr-2"
              />{{ $t('action.solvedPhoto') }}</el-dropdown-item>
            <el-dropdown-item command="attachment"><font-awesome-icon
                icon="fa-light fa-file"
                class="mr-2"
              />{{ $t('action.attachment') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-button-group>

      <!-- Export dropdown -->

      <div
        class="jumbo-dropdown-container"
        v-if="!isSmallScreen && $store.getters.selectedCount != 0"
      >
        <el-dropdown
          class="status-container mr-2"
          v-show="['table', 'tiny', 'map'].includes($store.getters.currentCard)"
          @command="handleReportCommand"
          size="small"
        >
          <el-button
            type="default"
            size="small"
          >
            <font-awesome-icon
              icon="fa-solid fa-download"
              class="fa-lg"
            />

          </el-button>

          <el-dropdown-menu slot="dropdown">

            <el-dropdown-item command="pdfSimple"><font-awesome-icon
                icon="fa-regular fa-file-pdf"
                class="fa-lg mr-2"
              />{{ $t('action.PDFsingle') }} </el-dropdown-item>
            <!-- <el-dropdown-item command="pdfHistory"><font-awesome-icon
                icon="fa-sharp fa-light fa-file-pdf"
                class="fa-lg mr-2"
              />{{ $t('action.PDFhistory') }}</el-dropdown-item> -->
            <el-dropdown-item command="pdfSynthesis"><font-awesome-icon
                icon="fa-solid fa-file-pdf"
                class="fa-lg mr-2"
              />{{ $t('action.PDFsynthesis') }}</el-dropdown-item>
            <el-dropdown-item command="xlsExport"><font-awesome-icon
                icon="fa-regular fa-file-excel"
                class="fa-lg mr-2"
              />{{ $t('action.exportXlS') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <!-- Edit button -->

      <el-button
        class="explore-mode-btn"
        id=""
        @click="handleReportCommand('EDIT')"
        v-b-tooltip.hover.bottom.html
        :title="$t('action.edit')"
        size="small"
        v-if="!isSmallScreen && $store.getters.selectedCount != 0"
      >
        <font-awesome-icon icon="fa-regular fa-pen" />
      </el-button>

      <!-- ACTIONS DROPDOWN (for small screens) -->

      <el-dropdown
        v-else-if="isSmallScreen && $store.getters.selectedCount != 0"
        class="status-container"
        @command="(command) => handleReportCommand(command)"
        size="small"
      >
        <el-button size="medium">
          {{ $t('explore.ACTIONS') }}
          <font-awesome-icon
            icon="fa-regular fa-angle-down"
            class="fa-lg ml-2"
          />
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            command="OPEN"
            class="text-left dropdown-action-item dropdown-action-open"
          >{{ $t('status.open') }}</el-dropdown-item>
          <el-dropdown-item
            command="ADDRESSED"
            class="text-left dropdown-action-item dropdown-action-addressed"
          >{{ $t('status.addressed') }}</el-dropdown-item>
          <el-dropdown-item
            command="ONGOING"
            class="text-left dropdown-action-item dropdown-action-ongoing"
          >{{ $t('status.ongoing') }}</el-dropdown-item>
          <el-dropdown-item
            command="SOLVED"
            class="text-left dropdown-action-item dropdown-action-solved"
          >{{ $t('status.solved')  }}</el-dropdown-item>
          <el-dropdown-item
            command="CLOSED"
            class="text-left dropdown-action-item dropdown-action-closed"
          >{{ $t('status.closed')  }}</el-dropdown-item>
          <el-dropdown-item
            command="ONHOLD"
            class="text-left dropdown-action-item dropdown-action-onHold"
          >{{ $t('status.onhold')  }}</el-dropdown-item>
          <el-dropdown-item
            command="NOACTION"
            class="text-left dropdown-action-item dropdown-action-noAction"
          >{{ $t('status.noaction')  }}</el-dropdown-item>
          <el-dropdown-item
            command="DELETED"
            class="text-left dropdown-action-item dropdown-action-deleted"
          >{{ $t('status.deleted')  }}</el-dropdown-item>

          <hr>

          <el-dropdown-item command="WI">
            <font-awesome-layers>
              <font-awesome-icon
                icon="fa-light fa-square"
                transform="grow-6 rotate-45"
              />
              <font-awesome-layers-text
                class="gray8"
                transform="shrink-6"
                value="WI"
              />
            </font-awesome-layers>
            &nbsp;{{ $t('action.wi')  }}
          </el-dropdown-item>

          <el-dropdown-item command="COMMENT">
            <font-awesome-icon
              icon="fa-light fa-comment"
              size="lg"
            /> {{ $t('action.comment')  }}
          </el-dropdown-item>

          <!-- <el-dropdown-item command="attachment">
            <font-awesome-icon
              icon="fa-light fa-paperclip"
              size="lg"
            /> {{ $t('action.document')  }}
          </el-dropdown-item> -->

          <!-- <el-dropdown-item command="closurePhoto">
            <font-awesome-icon
              icon="fa-regular fa-image"
              size="lg"
            /> {{ $t('action.closurePhoto')  }}
          </el-dropdown-item> -->

          <!-- <el-dropdown-item command="solvedPhoto">
            <font-awesome-icon
              icon="fa-light fa-image"
              size="lg"
            /> {{ $t('action.solvedPhoto')  }}
          </el-dropdown-item> -->

          <hr>

          <el-dropdown-item command="pdfSimple"><font-awesome-icon
              icon="fa-regular fa-file-pdf"
              class="fa-lg mr-2"
            />{{ $t('action.PDFsingle') }} </el-dropdown-item>
          <!-- <el-dropdown-item command="pdfHistory"><font-awesome-icon
                icon="fa-sharp fa-light fa-file-pdf"
                class="fa-lg mr-2"
              />{{ $t('action.PDFhistory') }}</el-dropdown-item> -->
          <el-dropdown-item command="pdfSynthesis"><font-awesome-icon
              icon="fa-solid fa-file-pdf"
              class="fa-lg mr-2"
            />{{ $t('action.PDFsynthesis') }}</el-dropdown-item>
          <el-dropdown-item command="xlsExport"><font-awesome-icon
              icon="fa-regular fa-file-excel"
              class="fa-lg mr-2"
            />{{ $t('action.exportXlS') }}</el-dropdown-item>

          <hr>

          <el-dropdown-item command="EDIT">
            <font-awesome-icon
              icon="fa-light fa-pen-square"
              size="lg"
            /> {{ $t('action.edit')  }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <!-- Selection dropwown -->
      <!-- v-if="$store.getters.selectedCount != 0" -->
      <div class="action-dropdown-container">
        <el-dropdown
          trigger="click"
          class="mr-2"
          split-button
          :type="$store.getters.selectionMode ? 'primary' : 'default'"
          v-show="['table', 'tiny', 'map'].includes($store.getters.currentCard)"
          @command="handleSelectionCommand"
          @click="toggleSelectionMode"
          size="medium"
        >
          <div v-if="!isSmallScreen">
            <span :class="$store.getters['selectionMode'] ? 'el-dropdown-link-selectionMode' : 'el-dropdown-link'">
              {{ $t('explore.selected').toUpperCase() }}: {{ $store.getters.selectedCount }}
              {{ $tc('explore.point', $store.getters['selectedCount']).toUpperCase() }}
            </span>
          </div>
          <div v-else>
            <font-awesome-icon
              icon="fa-regular fa-square-dashed"
              class="mr-2"
            />
            {{ $store.getters.selectedCount }}
            {{ $tc('explore.point', $store.getters['selectedCount']).toUpperCase() }}
          </div>

          <el-dropdown-menu slot="dropdown">

            <el-dropdown-item :command="$store.getters.selectionMode ? 'exitSelection' : 'showSelection'">
              <font-awesome-icon
                :icon="selectionIcon"
                class="mr-2"
              />
              {{ selectionText }}
            </el-dropdown-item>

            <el-dropdown-item command="emptySelection">
              <font-awesome-icon
                icon="fa-light fa-eraser"
                class="mr-2"
              />
              {{ $t('explore.selection.emptySelection') }}
            </el-dropdown-item>

            <el-dropdown-item command="saveSelection">
              <font-awesome-icon
                icon="fa-light fa-floppy-disk"
                class="mr-2"
              />
              {{ $t('explore.selection.newSelection') }}
            </el-dropdown-item>

            <el-dropdown-item
              v-for="sel in savedSelections"
              :key="sel.selectionUuid"
              :command="sel.selectionUuid"
              class="no-hover-style"
              divided
            >
              <div class="dropdown-content">
                <span
                  class="main-text"
                  @click.stop="enterSavedSelection(sel.selectionUuid)"
                >{{ sel.name }}</span>
                <span class="sub-text">{{ sel.count }} items</span>
                <div class="icon-row">

                  <!-- Selection button: ENTER -->
                  <el-tooltip
                    :content="$t('action.enter')"
                    placement="bottom"
                    effect="dark"
                  >
                    <el-button
                      type="text"
                      class="icon-button"
                      @click.stop="enterSavedSelection(sel.selectionUuid)"
                    >
                      <font-awesome-icon
                        icon="fa-light fa-arrow-right-to-arc"
                        class="mr-1"
                      />
                    </el-button>
                  </el-tooltip>

                  <!-- Selection button: ADD -->
                  <el-tooltip
                    :content="$t('action.add')"
                    placement="bottom"
                    effect="dark"
                  >
                    <el-button
                      type="text"
                      class="icon-button"
                      @click.stop="addToSelection(sel.selectionUuid)"
                    >
                      <font-awesome-icon
                        icon="fa-sharp fa-light fa-plus"
                        class="mr-1"
                      />
                    </el-button>
                  </el-tooltip>

                  <!-- Selection button: RESAVE -->
                  <el-tooltip
                    :content="$t('action.save')"
                    placement="bottom"
                    effect="dark"
                  >
                    <el-button
                      type="text"
                      class="icon-button"
                      @click.stop="resaveSelection(sel.selectionUuid)"
                    >
                      <font-awesome-icon
                        icon="fa-sharp fa-light fa-floppy-disk"
                        class="mr-1"
                      />
                    </el-button>
                  </el-tooltip>

                  <!-- Selection button: RENAME -->
                  <el-tooltip
                    :content="$t('action.rename')"
                    placement="bottom"
                    effect="dark"
                  >
                    <el-button
                      type="text"
                      class="icon-button"
                      @click.stop="editSelection(sel.selectionUuid)"
                    >
                      <font-awesome-icon
                        icon="fa-sharp fa-light fa-gear"
                        class="mr-1"
                      />
                    </el-button>
                  </el-tooltip>

                  <!-- Selection button: DELETE -->
                  <el-tooltip
                    :content="$t('action.delete')"
                    placement="bottom"
                    effect="dark"
                  >
                    <el-button
                      type="text"
                      class="icon-button"
                      @click.stop="deleteSelection(sel.selectionUuid)"
                    >
                      <font-awesome-icon
                        icon="fa-sharp fa-light fa-trash"
                        class="mr-1"
                      />
                    </el-button>
                  </el-tooltip>
                </div>
              </div>
            </el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <!-- <el-button
        class="mr-2"
        @click="emptySelection"
        v-if="$store.getters.selectedCount != 0 && $store.getters.currentCard != 'tiny' && $store.getters.currentCard != 'map'"
        size="small"
      ><i class="el-icon-close el-icon-right"></i> {{ $t('explore.clear').toUpperCase() }}</el-button> -->

      <!-- <el-button
        class="mr-2 ml-0"
        @click="exitSelection"
        v-if="$store.getters.selectionMode"
      >
        <font-awesome-icon
          icon="fa-light fa-arrow-right-from-bracket"
          size="sm"
        />
        {{ $t('explore.exit').toUpperCase() }}
      </el-button> -->

      <!-- Tab switch button group -->

      <div class="explore-mode">
        <el-button-group>
          <el-button
            class="explore-mode-btn"
            id="tiny"
            @click="switchTab"
            size="small"
          >
            <font-awesome-icon
              icon="fa-solid fa-grid"
              class="fa-lg"
            />
          </el-button>
          <el-button
            class="explore-mode-btn"
            id="map"
            @click="switchTab"
            size="small"
          >
            <font-awesome-icon
              icon="fa-solid fa-map"
              class="fa-lg"
            />
          </el-button>
          <el-button
            class="explore-mode-btn"
            id="table"
            @click="switchTab"
            size="small"
          >
            <font-awesome-icon
              icon="fa-solid fa-list"
              class="fa-lg"
            />
          </el-button>
        </el-button-group>

      </div>
    </div>

    <!-- ===================== LAYOUT ===================== -->

    <!-- Sidebar -->

    <div
      id="mySidebar"
      class="filter-sidebar d-none d-sm-block"
      ref="sideBarFilter"
    >
      <Filters purpose='EXPLORE' />
    </div>

    <!-- Main -->

    <div id="main">
      <component
        v-bind:is="currentTabComponent"
        class="tab"
      ></component>

    </div>

    <!-- Action control -->

    <ActionControl />

    <!-- Modals -->

    <ModalAddress
      ref="addressModal"
      :reportUuid="currentReportUuid"
    />

    <ModalWI />
    <ModalComments />
    <ModalSelectionSave
      ref="modalSelectionSave"
      id="modal-selection-save"
      :selectionToEdit="editingSelection"
      @hidden="resetEditingSelection"
    />

  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { reportActionMixin } from '../../mixins/reportActionMixin';
import { selectionMixin } from '@/mixins/selectionMixin';
import { sortMixin } from '@/mixins/sortMixin';
import axiosResource from '@/utils/axiosResource';
import Filters from '../../components/SideBarFilter.vue';
import ActionControl from '../../components/Explore/ActionControl.vue';
import TinyView from '../../components/Explore/TinyCardView.vue';
import TableView from '../../components/Explore/TableView.vue';
import MapView from '../../components/Explore/MapView.vue';
import ModalAddress from '@/components/Modals/ModalAddress.vue';
import ModalWI from '@/components/Modals/ModalWI.vue';
import ModalComments from '@/components/Modals/ModalComments.vue';
import ModalSelectionSave from '@/components/Modals/ModalSelectionSave.vue';
import ModalPhotoAction from '@/components/Modals/ModalPhotoAction.vue';
import swal from 'sweetalert2';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'explore',
  mixins: [reportActionMixin, selectionMixin, sortMixin],
  components: {
    Filters,
    ActionControl,
    TinyView,
    TableView,
    MapView,
    ModalAddress,
    ModalWI,
    ModalComments,
    ModalSelectionSave,
    ModalPhotoAction,
  },
  data() {
    return {
      dropdownKey: Date.now(),
      editingSelection: null, // Holds the selection object to edit
      isSmallScreen: window.matchMedia('screen and (max-width: 1500px)')
        .matches,
      sidebarOpen: this.$store.getters.filterSidebar,
      currentTab: '',
      loading: false,
      currentReportUuid: null, // To hold the current reportUuid
      sortingOptions: [
        {
          command: 'latestReports',
          label: 'sort.latestReports',
          divided: false,
        },
        {
          command: 'lastStatusChangeDate',
          label: 'sort.lastStatusChangeDate',
          divided: false,
        },
        { command: 'lastModified', label: 'sort.lastModified', divided: false },
        { command: 'idAsc', label: 'sort.idAsc', divided: true }, // Divider before this item
        { command: 'idDesc', label: 'sort.idDesc', divided: false },
        { command: 'refAsc', label: 'sort.refAsc', divided: false },
        { command: 'refDesc', label: 'sort.refDesc', divided: false },
        { command: 'wiAsc', label: 'sort.wiAsc', divided: false },
        { command: 'wiDesc', label: 'sort.wiDesc', divided: false },
        { command: 'programAsc', label: 'sort.programAsc', divided: false },
        { command: 'programDesc', label: 'sort.programDesc', divided: false },
        {
          command: 'descriptionAsc',
          label: 'sort.descriptionAsc',
          divided: false,
        },
        {
          command: 'descriptionDesc',
          label: 'sort.descriptionDesc',
          divided: false,
        },
      ],
    };
  },
  beforeDestroy() {
    const navbar = document.getElementById('navbar');
    navbar.classList.add('navbar-shadow');
    window.removeEventListener('resize', this.updateScreenSize); // Clean up
  },
  mounted() {
    this.$store.commit('loading', true);
    const navbar = document.getElementById('navbar');
    navbar.classList.remove('navbar-shadow');
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);
    const tabBtn = document.getElementById(this.$store.getters.currentCard);
    tabBtn.classList.add('el-btn-selected');

    // if (this.isMobile()) {
    //   this.$store.commit('setCurrentCard', 'tiny');
    // }

    if (this.sidebarOpen) {
      this.openNav();
    } else {
      this.closeNav();
    }

    this.fitMobile();

    this.initSelectionCount();

    // window.addEventListener('resize', () => {
    //   console.log('Resize detected');
    //   console.log(window.matchMedia('screen and (max-width: 768px)').matches);
    // });
    this.updateScreenSize(); // Set initial value
    window.addEventListener('resize', this.updateScreenSize);
    // fetchSavedSelections
    this.$store.dispatch('fetchSavedSelections');
  },
  created() {
    setTimeout(() => {
      this.fitMobile();
    }, 1000);

    window.addEventListener('resize', this.fitMobile);
    // Listen for the event from ArticleDetailed.vue
    this.$on('open-address-modal', this.openAddressModal);
  },
  destroyed() {
    window.removeEventListener('resize', this.fitMobile);
  },
  computed: {
    // Computed property to access saved selection from store
    savedSelections() {
      return this.$store.getters['savedSelections'];
    },
    // Fetch the current sorting preference from Vuex
    currentSortingPreference() {
      return this.$store.getters.getSortingPreference;
    },
    currentTabComponent: function () {
      return this.$store.getters.currentCard.toLowerCase() + '-view';
    },
    selectionText() {
      return this.$store.getters.selectionMode
        ? this.$t('explore.selection.exitSelection')
        : this.$t('explore.selection.enterSelection');
    },
    selectionIcon() {
      return this.$store.getters.selectionMode
        ? 'fa-light fa-arrow-left-from-arc'
        : 'fa-light fa-arrow-right-to-arc';
    },
  },
  methods: {
    async addToSelection(selectionUuid) {
      try {
        // Call the new backend endpoint to merge temporary reports into the saved selection.
        const response = await axiosResource.post(
          `selection/saved/${selectionUuid}/add`
        );
        if (response.data.success) {
          // Refresh the saved selections list so the UI reflects any updated counts.
          await this.$store.dispatch('selection/fetchSavedSelections');
          // Optionally, update the temporary selection count as well.
          await this.initSelectionCount();
          this.$store.dispatch('fetchSavedSelections'); // Ensure all selections are up to date
        } else {
          swalBootsrap.fire({
            icon: 'error',
            title: this.$t('error.unableToAddReportsToSavedSelection'),
          });
        }
      } catch (error) {
        console.error('Error in addToSelection:', error);
        swalBootsrap.fire({
          icon: 'error',
          title: this.$t('error.unableToAddReportsToSavedSelection'),
        });
      }
    },
    async enterSavedSelection(selectionUuid) {
      console.log('Enter saved selection:', selectionUuid);
      // If there's a current temporary selection, ask the user for confirmation to empty it.
      if (this.$store.getters.selectedCount !== 0) {
        const result = await swalBootsrap.fire({
          title: this.$t('explore.selection.confirm.emptySelectionTitle'),
          text: this.$t('explore.selection.confirm.emptySelectionText'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('explore.selection.confirm.yes'),
          cancelButtonText: this.$t('explore.selection.confirm.no'),
        });
        if (result.isConfirmed) {
          await this.emptySelection();
        } else {
          return; // Abort if the user cancels.
        }
      }

      try {
        // Retrieve all report UUIDs from the saved selection.
        const response = await axiosResource.get(
          `selection/saved/${selectionUuid}/reports`
        );
        const reportUuids = response.data.reportUuids;
        if (reportUuids && reportUuids.length > 0) {
          // Transfer the saved reports into the temporary selection.
          const addResponse = await axiosResource.post('selection/temporary', {
            reportUuids,
          });
          if (addResponse.data.success) {
            // Update the temporary selection display.
            await this.showSelection();
            // Update selection count
            this.initSelectionCount();
            // Show a success alert using swalBootsrap.
            // swalBootsrap
            //   .fire({
            //     title: this.$t('success.done'),
            //     showConfirmButton: false,
            //     icon: 'success',
            //     timer: 1500,
            //   })
            //   .then(() => {
            //     // Additional actions after success alert, if needed.
            //   });
          } else {
            swalBootsrap.fire({
              icon: 'error',
              title: this.$t('error.unableToLoadSelection'),
            });
          }
        } else {
          swalBootsrap.fire({
            icon: 'error',
            title: this.$t('error.noReportsInSavedSelection'),
          });
        }
      } catch (error) {
        console.error('Error transferring saved selection:', error);
        swalBootsrap.fire({
          icon: 'error',
          title: this.$t('error.loadingSavedSelection'),
        });
      }
    },
    editSelection(selectionUuid) {
      const selection = this.savedSelections.find(
        (sel) => sel.selectionUuid === selectionUuid
      );
      if (selection) {
        this.editingSelection = selection;
        // Use $nextTick to ensure editingSelection is updated before showing the modal.
        this.$nextTick(() => {
          this.$bvModal.show('modal-selection-save');
        });
      } else {
        console.warn('Selection not found:', selectionUuid);
      }
    },
    // This method resets editingSelection when the modal is closed.
    resetEditingSelection() {
      console.log(`resetEditingSelection triggered`);
      this.editingSelection = null;
    },
    async resaveSelection(selectionUuid) {
      // Ask for confirmation using swalBootsrap
      const result = await swalBootsrap.fire({
        title: this.$t('explore.selection.confirm.resaveSelectionTitle'),
        text: this.$t('explore.selection.confirm.resaveSelectionText'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('explore.selection.confirm.yes'),
        cancelButtonText: this.$t('explore.selection.confirm.no'),
      });
      if (!result.isConfirmed) return;

      try {
        // Call the resave endpoint (PUT request)
        const response = await axiosResource.put(
          `selection/saved/${selectionUuid}/resave`
        );
        if (response.data.success) {
          // Refresh the saved selections list and update temporary selection count.
          await this.$store.dispatch('fetchSavedSelections');
          await this.initSelectionCount();
          swalBootsrap.fire({
            title: this.$t('explore.selection.success.selectionResaved'),
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          });
        } else {
          swalBootsrap.fire({
            title: this.$t('explore.selection.error.unableToResaveSelection'),
            icon: 'error',
          });
        }
      } catch (error) {
        console.error('Error resaving selection:', error);
        swalBootsrap.fire({
          title: this.$t('explore.selection.error.unableToResaveSelection'),
          icon: 'error',
        });
      }
    },
    async deleteSelection(selectionUuid) {
      // Ask for confirmation
      const result = await swalBootsrap.fire({
        title: this.$t('explore.selection.confirm.deleteSelectionTitle'),
        text: this.$t('explore.selection.confirm.deleteSelectionText'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('explore.selection.confirm.yes'),
        cancelButtonText: this.$t('explore.selection.confirm.no'),
      });
      if (!result.isConfirmed) return;

      try {
        // Call DELETE API endpoint
        const response = await axiosResource.delete(
          `selection/saved/${selectionUuid}`
        );
        if (response.data.success) {
          // Refresh saved selections in the store (or remove the item locally)
          await this.$store.dispatch('fetchSavedSelections');
          swalBootsrap.fire({
            title: this.$t('explore.selection.success.selectionDeleted'),
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          });
        } else {
          swalBootsrap.fire({
            title: this.$t('explore.selection.error.unableToDeleteSelection'),
            icon: 'error',
          });
        }
      } catch (error) {
        console.error('Error deleting selection:', error);
        swalBootsrap.fire({
          title: this.$t('explore.selection.error.unableToDeleteSelection'),
          icon: 'error',
        });
      }
    },
    ...mapMutations(['SAVE_CONTEXT']),
    updateScreenSize() {
      this.isSmallScreen = window.matchMedia(
        'screen and (max-width: 1500px)'
      ).matches;
    },
    async initSelectionCount() {
      try {
        const response = await axiosResource.post('/selection/selection-count');
        if (response.data.success) {
          const selectedCount = response.data.selectedCount;
          this.$store.dispatch('updateSelectedCount', selectedCount);
        }
      } catch (error) {
        console.error('Error initializing selection count:', error);
      }
    },
    async toggleSelectionMode() {
      this.$store.commit('resetQttLoaded');
      if (this.$store.getters.selectionMode) {
        await this.exitSelection();
      } else {
        await this.showSelection();
      }
    },
    fitMobile() {
      if (window.matchMedia('screen and (max-width: 480px)').matches) {
        // TODO: this is called on scroll for mobiles (add alert to notice)
        this.$store.commit('setCurrentCard', 'tiny');
        document.getElementById('main').style.marginLeft = '0';
        document.getElementById('main').style.marginRight = '0';
        document.getElementById('main').style.width = '100%';
      }
    },
    isMobile() {
      if (window.matchMedia('screen and (max-width: 768px)').matches) {
        return true;
      }
    },
    triggerSidebar() {
      if (!this.sidebarOpen) {
        this.openNav();
      } else {
        this.closeNav();
      }
      this.sidebarOpen = !this.sidebarOpen;
    },
    openNav() {
      this.$store.commit('filterSidebar', true);
      document.getElementById('mySidebar').style.width = '392px';
      document.getElementById('mySidebar').style.opacity = '100';
      document.getElementById('main').style.marginLeft = '392px';
      const width = document.body.offsetWidth - 392;
      document.getElementById('main').style.width = `${width}px`;
      // const width = document.body.offsetWidth - 392;
      // document.getElementById('main').style.width = `${width}px`;

      // If on tableView mode
      if (this.$store.getters.currentCard == 'table') {
        document.getElementById('infiniteScrollTable').style.borderLeft =
          '1px solid rgb(229, 232, 235)';
      }
    },
    closeNav() {
      this.$store.commit('filterSidebar', false);
      document.getElementById('mySidebar').style.opacity = '0';
      document.getElementById('mySidebar').style.width = '52px';
      document.getElementById('main').style.marginLeft = '52px';
      const width = document.body.offsetWidth - 104;
      document.getElementById('main').style.width = `${width}px`;
      // If on tableView mode
      if (this.$store.getters.currentCard == 'table') {
        document.getElementById('infiniteScrollTable').style.borderLeft =
          'none';
      }
    },
    switchTab(e) {
      // currentTab = 'tiny-card'

      const tinyBtn = document.getElementById('tiny');
      const mapBtn = document.getElementById('map');
      const tableBtn = document.getElementById('table');
      tinyBtn.classList.remove('el-btn-selected');
      mapBtn.classList.remove('el-btn-selected');
      tableBtn.classList.remove('el-btn-selected');

      this.currentTab = e.currentTarget.id;

      this.$store.commit('setCurrentCard', e.currentTarget.id);
      const tabBtn = document.getElementById(this.currentTab);
      tabBtn.classList.add('el-btn-selected');
    },
  },
};
</script>

<style scoped>
/* Remove hover and focus styles only for the specific dropdown item */
.no-hover-style.el-dropdown-menu__item:focus,
.no-hover-style.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: transparent !important;
  color: inherit !important;
}
</style>

<style scoped>
/* Container for dropdown content */
.dropdown-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Text styling with minimal vertical spacing */
.main-text {

  display: block;
  margin: 2px 0px 4px 0px;
  padding: 0;
  line-height: 1; /* Minimize line-height to reduce vertical spacing */
  text-align: left;
}


.sub-text {
  display: block;
  margin: 0;
  padding: 0;
  line-height: 1; /* Minimize line-height to reduce vertical spacing */
  text-align: left;
}

.main-text {
  color: #13CE66;
  font-size: 14px;
}

.sub-text {
  font-size: 10px;
  margin-top: 2px; /* Slight gap if needed, adjust as necessary */
}

/* Icon row styling */
.icon-row {
  display: flex;
  justify-content: flex-end; /* Align icons to the right */
  gap: 6px; /* Small gap between icons */
  margin-top: 2px; /* Small space between text and icons */
}

/* Icon button styling */
.icon-button {
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}
</style>


<style scoped>


.explore-navbar {
    position: fixed;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0px 52px;
    top: 52px;
    width: 100%;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px;
}
.filter-button {
    background: rgb(255, 255, 255);
    /* display: flex; */
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    /* justify-content: center; */
    width: 42px;
    height: 42px;
    padding: 12px;
    border: none;
    border-radius: 50%;
}
.filter-button:hover {
    transition: all 0.2s ease 0s !important;
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px !important;
}


@media (min-width: 1200px) {
  .explore-navbar-container-one {
    width: 360px;
  }
}

.status-container {
  margin-right: auto; 
}



.el-dropdown-menu--small .el-dropdown-menu__item {
    line-height: 30px;
    padding: 0 17px;
    font-size: 13px;
}

.action-dropdown-container {
    /* width: 400px; */
    /* margin-right: 0;*/
    margin-left: auto; 
    /* display: flex; */
    /* justify-content: right; */
}
/* .action-dropdown-container.el-button {
    padding: 40px 20px;
} */
/* .action-dropdown-container.el-button-group.el-button:hover, .action-dropdown-container.el-button-group.el-button:focus  {
    background: rgba(229, 232, 235, 0.5) !important;
    color: #606266 !important;
    border-color: rgb(229, 232, 235) !important;
} */
/* .el-button:hover {
     background: rgba(229, 232, 235, 0.5) !important;
    color: #606266 !important;
    border-color: rgb(229, 232, 235) !important;
} */


.explore-mode {
    /* width: 200px;; */
    /* margin-right: 0; */
    /* margin-left: auto; */
    /* display: flex;
    justify-content: right; */
}
/* Explore mode button grou[ */
.explore-mode-btn {
    border-color: rgb(229, 232, 235) !important;
}
.explore-mode-btn:hover, .explore-mode-btn:focus {
    background: rgba(229, 232, 235, 0.5);
    color: rgb(4, 17, 29);
    border-color:  rgb(229, 232, 235) !important;
}
.el-btn-selected {
  color: rgb(4, 17, 29);
    border-color: rgb(229, 232, 235);
    background-color: rgba(229, 232, 235, 0.5);
}
.el-button {
    border-radius: 12px;
    border-color:  rgb(229, 232, 235) !important;
    /* border: 2px solid; */
}
.el-button:focus, .el-button:hover {
    background: rgba(229, 232, 235, 0.5) !important;
    color: #606266 !important;
    border-color: rgb(229, 232, 235) !important;
}



/* .el-button:focus, .el-button:hover {
    color: #409EFF;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
} */

.el-button-group>.el-button:first-child {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    /* border: 2px solid; */
}
.el-button-group>.el-button:not(:first-child):not(:last-child) {
    /* border-top: 2px solid; */
    /* border-bottom: 2px solid; */
}
.el-button-group>.el-button:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    /* border: 2px solid; */
}

.el-dropdown-menu {
    border-radius: 12px;
}
.el-dropdown-menu>.el-button-group>.el-button:first-child {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
}
.dropdown-action>.el-button-group>.el-button:not(:first-child) {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.filter-sidebar {
    position: fixed;
    top: 120px;
    /* height: 100%; */
    height: calc(100vh - 120px);
    width: 0;
    z-index: -1;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    /* transition:  0.5s; */
    transition: all 0.2s ease 0s !important;
    transition: alpha 0.2s;
    /* padding-top: 150px; */
}


/* 
.el-popper .popper__arrow {
    display: none !important;
}
.el-popper .popper__arrow, .el-popper .popper__arrow::after {
    display: none !important;
}

.select:hover .el-input__inner {
    border-color: rgb(229, 232, 235) !important;
}
.el-select .el-input.is-focus .el-input__inner {
    border-color: rgb(229, 232, 235) !important;
}
.el-select .el-input__inner:focus {
    border-color: rgb(229, 232, 235) !important;
} */

.action-loader { 
    margin-left: 20px;
}

#main {
    /* width: 100%; */

    width: calc(100% - 392px);
    overflow: hidden;
    top: 120px;
    transition: margin-left .5s;
    z-index: -1;
    margin-right: 52px;
    background-color: #ffffff;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
    #main {
        position: fixed;
        padding: 16px;
    }
}


/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}


.dropdown-action-item {
    white-space: nowrap;
}




</style>

<style scoped>
.selected-filter {
  font-weight: bold;
  color: #409eff; /* Matches Element Plus primary color */
  background-color: #f0f9ff;
}
</style>