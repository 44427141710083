<template>
  <b-modal
    id="modal-photo-action"
    :title="modalTitle"
    size="lg"
    @hidden="onHidden"
  >
    <div class="modal-body">
      <!-- Use the new PhotoDropzone component -->
      <PhotoDropzone
        ref="photoDropzone"
        @file-selected="onFileSelected"
      />
    </div>
    <template #modal-footer="{ hide }">
      <b-button
        variant="light"
        size="sm"
        @click="hide"
      >
        {{ $t('action.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        size="sm"
        @click="submitPhoto"
      >
        {{ $t('action.save') }}
      </b-button>
    </template>
  </b-modal>
</template>
  
  <script>
import PhotoDropzone from '@/components/PhotoDropzone.vue';
import axiosResource from '@/utils/axiosResource';
import { globalSwal } from '@/plugins/globalSwal';

export default {
  name: 'ModalPhotoAction',
  components: {
    PhotoDropzone,
  },
  props: {
    // "closure" or "solved"
    photoType: {
      type: String,
      required: true,
      validator: (value) => ['closure', 'solved'].includes(value),
    },
    // The report object to update
    report: {
      type: Object,
      required: true,
    },
  },
  computed: {
    modalTitle() {
      return this.photoType === 'closure'
        ? this.$t('modal.photo.closureTitle')
        : this.$t('modal.photo.solvedTitle');
    },
  },
  methods: {
    onFileSelected(file) {
      // Optionally, handle the file selection event here.
      // The file is also available in the PhotoDropzone component via ref.
      console.log('File selected:', file);
    },
    async submitPhoto() {
      // Retrieve the file from the dropzone component via ref.
      const file = this.$refs.photoDropzone.selectedFile;
      if (!file) {
        globalSwal.errorSimple(this.$t('error.noFileSelected'));
        return;
      }
      const formData = new FormData();
      formData.append('image', file);
      try {
        // Send a PATCH request to update the report's photo.
        const response = await axiosResource.patch(
          `reports/${this.report.uuid}/photo?type=${this.photoType}`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        if (response.data.success) {
          globalSwal.successSimple(this.$t('success.photoUpdated'));
          this.$emit('photo-updated', response.data.photoKey);
          this.$bvModal.hide('modal-photo-action');
        } else {
          globalSwal.errorSimple(this.$t('error.photoUpdate'));
        }
      } catch (error) {
        console.error('Error uploading photo:', error);
        globalSwal.errorSimple(this.$t('error.photoUpdate'));
      }
    },
    onHidden() {
      // Reset the dropzone when the modal is closed.
      if (this.$refs.photoDropzone && this.$refs.photoDropzone.reset) {
        this.$refs.photoDropzone.reset();
      }
    },
  },
};
</script>
  
  <style scoped>
  /* Add any modal-specific styles if needed */
  </style>