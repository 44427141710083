<template>
  <b-row>
    <b-col
      cols="12"
      lg="12"
      xl="8"
    >
      <div
        class="dropzone"
        :class="{ 'active-dropzone': active }"
        @dragenter.prevent="toggleActive"
        @dragleave.prevent="toggleActive"
        @dragover.prevent
        @drop.prevent="toggleActive"
        @drop="droppedFile"
      >
        <label for="photoDropzoneFile">
          <i class="el-icon-upload"></i>
          <span>
            {{ $t('dropzone.drop') }}
            <span class="dropzone-blue">{{ $t('dropzone.click') }}</span>
          </span>
        </label>
        <input
          type="file"
          id="photoDropzoneFile"
          @change="selectedFile"
        />
      </div>
      <!-- Optional: show a preview of the selected file -->
      <div
        v-if="previewUrl"
        class="preview"
      >
        <img
          :src="previewUrl"
          alt="Preview"
        />
      </div>
    </b-col>
  </b-row>
</template>
  
  <script>
export default {
  name: 'PhotoDropzone',
  data() {
    return {
      active: false,
      selectedFile: null,
      previewUrl: '',
    };
  },
  methods: {
    toggleActive() {
      this.active = !this.active;
    },
    selectedFile(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        this.previewUrl = URL.createObjectURL(file);
        this.$emit('file-selected', file);
      }
    },
    droppedFile(event) {
      const file = event.dataTransfer.files[0];
      if (file) {
        this.selectedFile = file;
        this.previewUrl = URL.createObjectURL(file);
        this.$emit('file-selected', file);
      }
    },
    reset() {
      this.selectedFile = null;
      this.previewUrl = '';
      // Reset the file input element value:
      const input = this.$el.querySelector('input[type="file"]');
      if (input) input.value = '';
    },
  },
};
</script>
  
  <style scoped>
  .dropzone {
    width: 100%;
    height: 180px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    background-color: #fff;
    transition: 0.3s ease all;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .active-dropzone {
    background-color: rgba(32, 159, 255, 0.06);
    border: 2px dashed #409eff;
  }
  .preview {
    margin-top: 10px;
    text-align: center;
  }
  .preview img {
    max-width: 100%;
    max-height: 180px;
  }
  </style>